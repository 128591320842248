export const environment = {
  production: false,
  authServerHost: 'https://api.electromagnetism.dev.gametailors.com/auth/',
  casHost: 'https://api.electromagnetism.dev.gametailors.com/cas',
  // TODO: Do not hardcode this
  dataPackage: 'dev_datapackage-824b700b-2198-4b9f-8e7b-b6d2a7acf605',
  defaultGame: 'electro',
  aspectRatio: { width: 1920, height: 1080 },
  playGameUrl: 'https://play.electromagnetism.dev.gametailors.com/',

  version_latestBuildNumber: '1196',
  version_latestCommitHash: 'f7aa948f7e9bb8b24bb70fd2c22b6f196cf16a90',
  version_latestDate: '10/01/2025 12:31',
};
